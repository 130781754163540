export function grayOutFields(grayOut, fields) {
    fields.forEach((field) => {
        const element = jQuery(`[name="${field}"]`);
        element.attr('gray-out', grayOut);
        element.offsetHeight;
    });
}

export function createSelectElement(tagName, id, label, dataType, options = []) {
    const element = jQuery('<' + tagName + '>', {
        id: id,
        name: id,
        class: 'fl-field-control-wrapper'
    });

    if (options.length) {
        options.forEach(option => {
            element.append(jQuery('<option>', {
                value: option.value,
                text: option.text,
                selected: option.selected
            }));
        });
    }

    const tr = jQuery('<tr>', {
        id: 'fl-field-' + id,
        class: 'fl-field',
        'data-type': dataType,
        'data-is-style': 'true',
        'data-preview': '{"type":"none"}',
        'data-connections': 'false'
    });

    const th = jQuery('<th>', { class: 'fl-field-label' });
    const labelElement = jQuery('<label>', { htmlFor: id, text: label });
    th.append(labelElement);

    const td = jQuery('<td>', { class: 'fl-field-control' });
    const div = jQuery('<div>', { class: 'fl-field-control-wrapper' });
    div.append(element);
    td.append(div);
    tr.append(th);
    tr.append(td);

    return tr;
}

export function hexToRgb(hex) {
    hex = hex.replace(/^#/, '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgb(${r}, ${g}, ${b})`;
}

export function calculateRatioFromString(stringRatio) {
    const [width, height] = stringRatio.split('/');
    return parseFloat(width) / parseFloat(height);
}

export function updateElementValue(selector, value) {
    jQuery(selector).val(value).trigger('change');
}

export function getUniqueSelector($element) {
    if ($element.attr('id')) {
        return '#print-version #' + $element.attr('id');
    }
    if ($element.is('body')) {
        return 'body';
    }

    var path = [];
    while ($element.length) {
        if ($element.is('body')) {
            break;
        }
        var tagName = $element[0].tagName.toLowerCase();

        var className = $element.attr('class');
        if (className) {
            className = '.' + className.trim().split(/\s+/).join('.');
            tagName += className;
        }

        var siblings = $element.siblings(tagName.split('.')[0]); // Only match siblings by tag name
        if (siblings.length > 0) {
            tagName += ':nth-of-type(' + ($element.index() + 1) + ')';
        }
        path.unshift(tagName);
        $element = $element.parent();
    }

    if (path.length > 0 && path[0].startsWith('div')) {
        path[0] = 'div#print-version';
    }
    
    return path.join(' > ');
}
