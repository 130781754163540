import { addCustomFields, grayOutCertificateContentFields, createPrintContainer } from './domManipulation';

window.onload = function() {
    const topWindow = window.top;
    const topDocument = topWindow.document;

    setInterval(() => {
        const outermostRow = jQuery('#fl-main-content .fl-builder-content .fl-row:not(.fl-row .fl-row):first');
        window.outermostRowId = outermostRow.attr('data-node') ?? -1;
        const outermostCol = outermostRow.find('.fl-col:not(.fl-col .fl-col):first');
        const outermostColId = outermostCol.attr('data-node');
        window.outermostColId = outermostColId ?? -1;
    }, 500);

    window.outerRowFormIsOpen = false;

    function listenAndUpdateCertificateContainerForm() {
        const outerRowFormExists = jQuery(`form[data-node="${window.outermostRowId}"]`).length > 0;
        if (window.outerRowFormIsOpen && !outerRowFormExists) {
            window.outerRowFormIsOpen = false;
        } else if (!window.outerRowFormIsOpen && outerRowFormExists) {
            window.outerRowFormIsOpen = true;
            addCustomFields();
        }
    }

    window.outerColFormIsOpen = false;

    function listenAndUpdateCertificateContentForm() {
        const outerColFormExists = jQuery(`form[data-node="${window.outermostColId}"]`).length > 0;
        if (window.outerColFormIsOpen && !outerColFormExists) {
            window.outerColFormIsOpen = false;
        } else if (!window.outerColFormIsOpen && outerColFormExists) {
            window.outerColFormIsOpen = true;
            grayOutCertificateContentFields();
        }
    }

    setInterval(listenAndUpdateCertificateContainerForm, 300);
    setInterval(listenAndUpdateCertificateContentForm, 300);
    setInterval(listenAndUpdateCertificateContentForm, 300);


    const style = topWindow.document.createElement('style');
    style.textContent = `
        select[gray-out="true"] {
            color: light-dark(graytext, rgb(170, 170, 170)) !important;
            border-color: rgba(118, 118, 118, 0.3) !important;
            opacity: 0.5 !important;
            pointer-events: none !important;
        }
        body .fl-builder-settings-fields input[gray-out="true"] {
            cursor: default !important;
            background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3)) !important;
            color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170)) !important;
            border-color: rgba(118, 118, 118, 0.3) !important;
            pointer-events: none !important;
        }
    `;
    topDocument.body.appendChild(style);

    const outermostRow = jQuery('#fl-main-content .fl-builder-content .fl-row:not(.fl-row .fl-row):first');
    const outermostCol = outermostRow.find('.fl-col:not(.fl-col .fl-col):first');

    // // Get element width and height
    const containerWidth = outermostCol.width();
    const containerHeight = outermostCol.height();

    let printSizeRule = `${containerWidth}px ${containerHeight}px`;
    
    let printerStyle = document.createElement('style');
    printerStyle.setAttribute('media', 'print');

    printerStyle.innerHTML = `
        @media screen {
            @media print {
                @page {
                    size: ${printSizeRule};
                    margin: 0mm;
                    padding: 0;
                }

                body *,
                #query-monitor-main {
                    display: none !important;
                }

                #print-version,
                #print-version #fl-main-content {
                    display: block !important;
                }

                #print-version .fl-page {
                    display: block !important;
                }

                #print-version .fl-content,
                #print-version .fl-row,
                #print-version .fl-row-content-wrap,
                #print-version .fl-row-content,
                #print-version .container {
                    padding: 0 !important;
                    margin: 0 !important;
                }

                body, #print-version, #print-version .fl-content-full {
                    width: ${containerWidth}px !important;
                    min-width: ${containerWidth}px !important;
                    max-width: ${containerWidth}px !important;
                    height: ${containerHeight}px !important;
                    min-height: ${containerHeight}px !important;
                    max-height: ${containerHeight}px !important;
                    -webkit-print-color-adjust: exact;
                    print-color-adjust: exact;
                }
            }
        }
    `;
    topDocument.body.appendChild(printerStyle);

    window.onbeforeprint = function() {
        createPrintContainer();
    }

    window.onafterprint = function() {
        jQuery('#print-version').remove();
    }
}
