export const FIELD_IDS = {
    PAPER_SIZE: 'paper_size',
    ORIENTATION: 'orientation',
    WIDTH: 'width',
    MAX_CONTENT_WIDTH: 'max_content_width',
    MAX_CONTENT_WIDTH_UNIT: 'max_content_width_unit',
    CONTENT_WIDTH: 'content_width',
    FULL_HEIGHT: 'full_height',
    CONTENT_ALIGNMENT: 'content_alignment',
    BG_TYPE: 'bg_type',
    BG_COLOR: 'bg_color',
    COLOR_PICKER: 'fl-color-picker-color',
    ASPECT_RATIO: 'aspect_ratio',
    NODE_LABEL: 'node_label',
    MARGIN_TOP: 'margin_top',
    MARGIN_RIGHT: 'margin_right',
    MARGIN_BOTTOM: 'margin_bottom',
    MARGIN_LEFT: 'margin_left',
    MARGIN_UNIT: 'margin_unit',
    PADDING_TOP: 'padding_top',
    PADDING_RIGHT: 'padding_right',
    PADDING_BOTTOM: 'padding_bottom',
    PADDING_LEFT: 'padding_left',
    // Column specific fields
    WIDTH_SIZE: 'size',
    MIN_HEIGHT: 'min_height',
    MIN_HEIGHT_UNIT: 'min_height_unit',
};

export const ORIENTATIONS = {
    LANDSCAPE: { VALUE: 'LANDSCAPE', LABEL: 'Landscape'},
    PORTRAIT: { VALUE: 'PORTRAIT', LABEL: 'Portrait'}
};

export const PAPER_TYPES = {
    NONE: {
        VALUE: 'none',
        LABEL: 'None',
    },
    US_LETTER: {
        VALUE: 'US_LETTER',
        LABEL: 'US Letter',
        WIDTH: {
            [ORIENTATIONS.LANDSCAPE.VALUE]: 1056,
            [ORIENTATIONS.PORTRAIT.VALUE]: 816
        },
        HEIGHT: {
            [ORIENTATIONS.LANDSCAPE.VALUE]: 816,
            [ORIENTATIONS.PORTRAIT.VALUE]: 1056
        }
    },
    A4: {
        VALUE: 'A4',
        LABEL: 'A4',
        WIDTH: {
            [ORIENTATIONS.LANDSCAPE.VALUE]: 1123,
            [ORIENTATIONS.PORTRAIT.VALUE]: 794
        },
        HEIGHT: {
            [ORIENTATIONS.LANDSCAPE.VALUE]: 794,
            [ORIENTATIONS.PORTRAIT.VALUE]: 1123
        }
    },
    CUSTOM: { VALUE: 'CUSTOM', LABEL: 'Custom' }
};

export const MAX_CONTENT_WIDTH = {
    [ORIENTATIONS.LANDSCAPE.VALUE]: '60',
    [ORIENTATIONS.PORTRAIT.VALUE]: '40'
}

export const FULL_HEIGHT_VALUE = 'full';
export const DEFAULT_HEIGHT_VALUE = 'default';

export const BG_COLORS = {
    WHITE: 'ffffff',
    LIGHT_GRAY: 'dcdcdc'
};

export const MARGINS_ORIENTATION_BASED = {
    [ORIENTATIONS.LANDSCAPE.VALUE]: {
        TOP: '0',
        RIGHT: '20',
        BOTTOM: '0',
        LEFT: '20'
    },
    [ORIENTATIONS.PORTRAIT.VALUE]: {
        TOP: '0',
        RIGHT: '30',
        BOTTOM: '0',
        LEFT: '30'
    }
};