import { FIELD_IDS, PAPER_TYPES } from './constants';
import { grayOutFields } from './utils';
import { setFieldValuesForPaperSize, grayOutAllFieldsExceptPaperSizeAndOrientation, removeLoadingClassFromElement, updateChildElementSettings, grayOutAllFieldsExceptPaperSizeWidthAndHeight, setOuterContainerBackgroundColor, setOuterContainerNodeLabel } from './domManipulation';

export function handlePaperSizeChange() {
    const selectedPaperSizeValue = jQuery(`select[name="${FIELD_IDS.PAPER_SIZE}"]`).val();
    const orientationField = jQuery(`#fl-field-${FIELD_IDS.ORIENTATION}`);
    orientationField.show();

    if (selectedPaperSizeValue === PAPER_TYPES.NONE.VALUE) {
        orientationField.hide();
        grayOutFields(false, Object.values(FIELD_IDS));
        setFieldValuesForPaperSize();
        return;
    }

    if (selectedPaperSizeValue === PAPER_TYPES.CUSTOM.VALUE) {
        orientationField.hide();
        grayOutAllFieldsExceptPaperSizeWidthAndHeight();
        setOuterContainerBackgroundColor();
        setOuterContainerNodeLabel(selectedPaperSizeValue);
        return;
    }

    grayOutAllFieldsExceptPaperSizeAndOrientation();
    setFieldValuesForPaperSize();
}

export function handleOrientationChange() {
    const selectedPaperSize = jQuery(`select[name="${FIELD_IDS.PAPER_SIZE}"]`).val();
    if ([PAPER_TYPES.NONE.VALUE, PAPER_TYPES.CUSTOM.VALUE].includes(selectedPaperSize)) return;
    setFieldValuesForPaperSize();
}

export function didFinishSaveState(e, data) {
    if (data?.fl_builder_data?.action != 'save_settings') { return; }
    
    if (window.savingOuterElement === true) {
        window.savingOuterElement = false;
        setTimeout(() => {
            updateChildElementSettings();
        }, 15);
    } else {
        window.FLBuilder.removeHook('didCompleteAJAX', didFinishSaveState);
        const outermostRow = jQuery('#fl-main-content .fl-builder-content .fl-row:not(.fl-row .fl-row):first');
        removeLoadingClassFromElement(outermostRow);
    }
}

export function saveSettings() {
    window.paperTypeValue = jQuery(`select[name="${FIELD_IDS.PAPER_SIZE}"]`).val();
    if (window.paperTypeValue === PAPER_TYPES.CUSTOM.VALUE) {
        window.minHeightValue = jQuery(`input[name="${FIELD_IDS.MIN_HEIGHT}"]`).val();
    } else {
        window.minHeightValue = '';
    }
    window.orientationValue = jQuery(`select[name="${FIELD_IDS.ORIENTATION}"]`).val();
    window.savingOuterElement = true;
    const hasPaperType = window.paperTypeValue !== PAPER_TYPES.NONE.VALUE;
    if (hasPaperType) {
        window.FLBuilder.addHook('didCompleteAJAX', didFinishSaveState);
    }
}

export function setupEventHandlers() {
    jQuery(`select[name="${FIELD_IDS.PAPER_SIZE}"]`).on('change', handlePaperSizeChange);
    jQuery(`select[name="${FIELD_IDS.ORIENTATION}"]`).on('change', handleOrientationChange);
    jQuery('.fl-builder-settings-save').on('click', saveSettings);
}
