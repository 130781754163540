import { FIELD_IDS, PAPER_TYPES, ORIENTATIONS, BG_COLORS, DEFAULT_HEIGHT_VALUE } from './constants';
import { createSelectElement, hexToRgb, grayOutFields, updateElementValue, getUniqueSelector } from './utils';
import { setupEventHandlers } from './eventHandlers';

export function addCustomFields() {
    const labelValue = jQuery(`input[name="${FIELD_IDS.NODE_LABEL}"]`).val();
    const { paperSizeValue, orientationValue } = getPaperSizeAndOrientation(labelValue);

    const paperSizeOptions = createOptions(PAPER_TYPES, paperSizeValue);
    const orientationOptions = createOptions(ORIENTATIONS, orientationValue);

    const paperSizeField = createSelectElement('select', FIELD_IDS.PAPER_SIZE, 'Paper Size', 'select', paperSizeOptions);
    const orientationField = createSelectElement('select', FIELD_IDS.ORIENTATION, 'Orientation', 'select', orientationOptions);

    const widthField = jQuery(`#fl-field-${FIELD_IDS.WIDTH}`);
    widthField.before(paperSizeField);
    widthField.before(orientationField);
    
    setupEventHandlers();
    const paperSizeIsEmpty = paperSizeValue === PAPER_TYPES.NONE.VALUE;
    if (paperSizeIsEmpty) {
        orientationField.hide();
        return;
    }

    const paperSizeIsCustom = paperSizeValue === PAPER_TYPES.CUSTOM.VALUE;
    if (paperSizeIsCustom) {
        grayOutAllFieldsExceptPaperSizeWidthAndHeight();
        return;
    }

    grayOutAllFieldsExceptPaperSizeAndOrientation();
}

export function setFieldValuesForPaperSize() {
    const selectedPaperSize = jQuery(`select[name="${FIELD_IDS.PAPER_SIZE}"]`).val();
    const selectedOrientation = jQuery(`select[name="${FIELD_IDS.ORIENTATION}"]`).val();

    if (selectedPaperSize === PAPER_TYPES.NONE.VALUE) {
        resetAllFields();
        return;
    }

    setOuterContainerWidth(selectedPaperSize, selectedOrientation);
    setOuterContainerHeight(selectedPaperSize, selectedOrientation);
    setOuterContainerBackgroundColor();
    setOuterContainerNodeLabel(selectedPaperSize, selectedOrientation);
}

function resetAllFields() {
    const fullHeightElement = jQuery(`select[name="${FIELD_IDS.FULL_HEIGHT}"]`);
    updateElementValue(fullHeightElement, DEFAULT_HEIGHT_VALUE);
    const bgColorTypeElement = jQuery(`select[name="${FIELD_IDS.BG_TYPE}"]`);
    updateElementValue(bgColorTypeElement, 'none');
    const nodeLabelElement = jQuery(`input[name="${FIELD_IDS.NODE_LABEL}"]`);
    updateElementValue(nodeLabelElement, '');
}

function setOuterContainerWidth(selectedPaperSize, selectedOrientation) {
    updateElementValue(`select[name="${FIELD_IDS.WIDTH}"]`, 'fixed');

    const widthBorders = 40;
    const widthSize = PAPER_TYPES[selectedPaperSize].WIDTH[selectedOrientation] + widthBorders;
    updateElementValue(`input[name="${FIELD_IDS.MAX_CONTENT_WIDTH}"]`, widthSize);
}

function setOuterContainerHeight(selectedPaperSize, selectedOrientation) {
    updateElementValue(`select[name="${FIELD_IDS.FULL_HEIGHT}"]`, 'custom');

    const heightBorders = 40;
    const heightSize = PAPER_TYPES[selectedPaperSize].HEIGHT[selectedOrientation] + heightBorders;
    updateElementValue(`input[name="${FIELD_IDS.MIN_HEIGHT}"]`, heightSize);
}

export function setOuterContainerBackgroundColor() {
    updateBackgroundColor(BG_COLORS.LIGHT_GRAY);
}

export function setOuterContainerNodeLabel(paperSize, orientation = null) {
    if (!paperSize) { return; }
    if (paperSize === PAPER_TYPES.CUSTOM.VALUE) { 
        updateElementValue(`input[name="${FIELD_IDS.NODE_LABEL}"]`, PAPER_TYPES[paperSize].LABEL);
        return;
    } 

    const paperSizeLabel = PAPER_TYPES[paperSize]?.LABEL ?? '';
    const orientationLabel = ORIENTATIONS[orientation]?.LABEL ?? '';
    const nodeLabelValue = `${paperSizeLabel} - ${orientationLabel}`;
    updateElementValue(`input[name="${FIELD_IDS.NODE_LABEL}"]`, nodeLabelValue);
}

export function updateBackgroundColor(backgroundColor) {
    const bgColorTypeSelect = jQuery(`select[name="${FIELD_IDS.BG_TYPE}"]`);
    updateElementValue(bgColorTypeSelect, 'color');

    const bgColorInput = jQuery(`#fl-field-${FIELD_IDS.BG_COLOR} input[name="${FIELD_IDS.BG_COLOR}"]`);
    updateElementValue(bgColorInput, backgroundColor);

    const buttonBgColorInput = jQuery(`#fl-field-${FIELD_IDS.BG_COLOR} button.${FIELD_IDS.COLOR_PICKER}`);
    const rgbColor = hexToRgb(backgroundColor);
    buttonBgColorInput.css('backgroundColor', rgbColor).removeClass('fl-color-picker-empty');
}

export function updateChildElementSettings() {
    paperTypeValue = window.paperTypeValue;
    orientationValue = window.orientationValue;
    if (!paperTypeValue) { return; }
    
    const paperTypeIsNone = paperTypeValue === PAPER_TYPES.NONE.VALUE;

    const outermostRow = jQuery('#fl-main-content .fl-builder-content .fl-row:not(.fl-row .fl-row):first');
    const outermostCol = outermostRow.find('.fl-col:not(.fl-col .fl-col):first');
    const outermostColId = outermostCol.attr('data-node');

    addLoadingClassFromElement(outermostRow);

    window.FL.Builder.data.getLayoutActions().displaySettings(outermostColId);

    addLoadingClassFromElement(outermostRow);

    if (paperTypeIsNone) {
        clearContentFields();
    } else {
        updateBackgroundColor(BG_COLORS.WHITE);
        const nodeLabelElement = jQuery(`input[name="${FIELD_IDS.NODE_LABEL}"]`);
        updateElementValue(nodeLabelElement, 'Document content');
        const minHeightElement = jQuery(`input[name="${FIELD_IDS.MIN_HEIGHT}"]`);
        let minHeightValue = PAPER_TYPES[paperTypeValue]?.HEIGHT?.[orientationValue];
        if (window.minHeightValue) { minHeightValue = window.minHeightValue; }
        updateElementValue(minHeightElement, minHeightValue);
    }

    forceSaveSettingsClick();

    addLoadingClassFromElement(outermostRow);
}

export function grayOutAllFieldsExceptPaperSizeAndOrientation() {
    grayOutFields(true, Object.values(FIELD_IDS));
    grayOutFields(false, [FIELD_IDS.PAPER_SIZE]);
    grayOutFields(false, [FIELD_IDS.ORIENTATION]);
}

export function grayOutAllFieldsExceptPaperSizeWidthAndHeight() {
    grayOutFields(true, Object.values(FIELD_IDS));
    grayOutFields(false, [FIELD_IDS.PAPER_SIZE]);
    grayOutFields(false, [FIELD_IDS.MAX_CONTENT_WIDTH]);
    grayOutFields(false, [FIELD_IDS.MAX_CONTENT_WIDTH_UNIT]);
    grayOutFields(false, [FIELD_IDS.MIN_HEIGHT]);
    grayOutFields(false, [FIELD_IDS.MIN_HEIGHT_UNIT]);
}

export function removeLoadingClassFromElement(element) {
    element.removeClass('fl-builder-node-loading');
}

export function grayOutCertificateContentFields() {
    const nodeLabelValue = jQuery(`input[name="${FIELD_IDS.NODE_LABEL}"]`).val();
    if (nodeLabelValue !== 'Document content') { return }
    grayOutFields(true, [FIELD_IDS.WIDTH_SIZE]);
    grayOutFields(true, [FIELD_IDS.MIN_HEIGHT]);
    grayOutFields(true, [FIELD_IDS.MIN_HEIGHT_UNIT]);
    grayOutFields(true, [FIELD_IDS.NODE_LABEL]);
}

export function createPrintContainer() {
    let newDiv = jQuery('<div id="print-version"></div>');
    let clonedMainContent = jQuery('#fl-main-content').clone(true);
    newDiv.append(clonedMainContent);

    jQuery('#fl-main-content, #fl-main-content *').each(function(index) {
        const originalElement = jQuery(this);
        const clonedElement = newDiv.find('*').eq(index);

        const bgImage = originalElement.css('background-image');
        const bgColor = originalElement.css('background-color');

        if ((bgImage !== 'none' && bgImage !== '') || (bgColor !== 'rgba(0, 0, 0, 0)' && bgColor !== '')) {
            let importantStyle = '';
            if (bgImage !== 'none') {
                importantStyle += 'background-image: ' + bgImage + ' !important; ';
            }
            if (bgColor !== 'rgba(0, 0, 0, 0)') {
                importantStyle += 'background-color: ' + bgColor + ' !important; ';
            }

            let bgRepeat = originalElement.css('background-repeat');
            let bgPosition = originalElement.css('background-position');
            let bgAttachment = originalElement.css('background-attachment');
            let bgSize = originalElement.css('background-size');
            if (bgRepeat !== 'repeat' && bgRepeat !== '') {
                importantStyle += 'background-repeat: ' + bgRepeat + ' !important; ';
            }
            if (bgPosition !== '0% 0%' && bgPosition !== '') {
                importantStyle += 'background-position: ' + bgPosition + ' !important; ';
            }
            if (bgAttachment !== 'scroll' && bgAttachment !== '') {
                importantStyle += 'background-attachment: ' + bgAttachment + ' !important; ';
            }
            if (bgSize !== 'auto' && bgSize !== '') {
                importantStyle += 'background-size: ' + bgSize + ' !important; ';
            }
            clonedElement.attr('style', importantStyle);
        }
    });

    newDiv.find('.fl-post-header').remove();
    newDiv.find('.fl-post-meta').remove();

    jQuery('body').append(newDiv);

    let displayStyle = document.createElement('style');

    jQuery('#print-version *').each(function(index) {
        const element = jQuery(this);
        const uniqueSelector = getUniqueSelector(element);
        const display = element.css('display');
        const cssDisplayRule = `display: ${display} !important`;

        if (display !== 'none') {
            displayStyle.innerHTML += `@media print { ${uniqueSelector} { ${cssDisplayRule} }} `; 
        }   
    });

    // Get the min-height '#print-version .fl-col-content' and recalc it -40px
    const flColContent = jQuery('#print-version .fl-row-content-wrap');
    const flColContentMinHeight = flColContent.css('min-height');
    const flColContentMinHeightValue = parseInt(flColContentMinHeight.replace('px', ''));
    const newMinHeight = flColContentMinHeightValue - 45;
    flColContent.css('min-height', `${newMinHeight}px`);
    flColContent.css('max-height', `${newMinHeight}px`);
    const topWindow = window.top;
    const topDocument = topWindow.document;
    topDocument.body.appendChild(displayStyle);
}

function addLoadingClassFromElement(element) {
    element.addClass('fl-builder-node-loading');
}

function forceSaveSettingsClick() {
    jQuery('.fl-builder-settings-save').click();
}

function createOptions(options, selectedValue) {
    return Object.values(options).map(item => ({
        value: item.VALUE,
        text: item.LABEL,
        selected: item.VALUE === selectedValue
    }));
}

function getPaperSizeAndOrientation(nodeValue) {
    if (!nodeValue) { 
        const paperSizeInitialValue = PAPER_TYPES.NONE.VALUE;
        const orientationInitialValue = ORIENTATIONS.LANDSCAPE.VALUE
        return { 
            'paperSizeValue': paperSizeInitialValue, 
            'orientationValue': orientationInitialValue
        };
    }

    const [paperSizeLabel, orientationLabel] = nodeValue.split(' - ');
    const paperSizeSelectedValue = Object.values(PAPER_TYPES).find(item => item.LABEL === paperSizeLabel)?.VALUE;
    const orientationSeletedValue = Object.values(ORIENTATIONS).find(item => item.LABEL === orientationLabel)?.VALUE;

    return {
        'paperSizeValue': paperSizeSelectedValue,
        'orientationValue': orientationSeletedValue
    };
}

function clearContentFields() {
    const nodeLabelElement = jQuery(`input[name="${FIELD_IDS.NODE_LABEL}"]`);
    updateElementValue(nodeLabelElement, '');

    grayOutFields(false, Object.values(FIELD_IDS));
}